import * as React from "react"
import QRRedirect from "../../components/qrRedirect"

//TODO - UTM
const QR_PressSummer2023Card = () => {
  return (
    <main>
      <title>Redirecting...</title>
      <QRRedirect source="press-summer-2023-card" destination="https://presskit.bishopgames.com/sheet.php?p=towers_of_thana" />
    </main>
  )
}

export default QR_PressSummer2023Card