import Analytics from "../utils/analytics";

interface Props {
  source: string;
  destination?: string;
}

const QRRedirect: React.FC<Props> = ({ destination = "/", source }) => { 
  Analytics.redirectEventObject({
    event: "qr_code_scanned",
    qr_code_source: source
  },
  destination);
  return null;
}

export default QRRedirect;